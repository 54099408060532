/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '../utils/utils';
import { nominalTypeHack } from 'prop-types';
import Radium from 'radium'
import styled from "styled-components";
import {Helmet} from "react-helmet";

const Input = styled.input`
    &:focus{
        outline:none;
    }
`

const styles = {
  wrapper: {
    height: '100vh',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width:'50%',
    margin: '0 auto',
    fontFamily: 'Montserrat'
  },
  input: {
    width: '100%',
    height: '48px',
    borderRadius: '4px',
    boxShadow: 'none',
    border: 'none',
    background: 'lightgoldenrodyellow',
    padding:'0 0 0 15px',
    boxSizing: 'border-box',
    ':focus':{
        outline: 'none',
    }
  },
  button: {
    width: '100%',
    height: '48px',
    background: '#fff',
    color: '#000',
    border: '1px solid rebeccapurple',
    borderRadius: '4px',
    marginTop: '16px',
    textTransform: 'uppercase',
    fontWeight: '300',
    fontFamily: 'sans-serif'
  },
  buttonHover: {
    background: '#000',
    color: '#fff',
    cursor: 'pointer'
  },
  link: {
    color: '#fff',
    fontFamily: 'sans-serif'
  },
  linkHover: {
    color: 'dodgerblue'
  }
};

const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);
  const [isThemeHovered, themeHover] = useState(false);
  const [isSiteHovered, siteHover] = useState(false);

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (

    <>

    <Helmet>
      {/* General tags */}
      <title>Riel Kitson | Industrial Designer</title>
      <meta name="description" content="Riel Kitson website coming soon..." />
      {/* <meta name="image" content={image} /> */}
      {/* <link rel="canonical" href={url} /> */}
    </Helmet>

    <div style={styles.wrapper}>
      <h1 style={{ fontSize:'32px', fontWeight:'400', color: '#000', textAlign: 'center' }}>Riel Kitson | Industrial Designer</h1>
      <h2 style={{ fontWeight:'400', color: '#000' }}>Coming Soon</h2>
      <h4 style={{ fontWeight:'400', color: '#000' }}>Enter Password</h4>

      <form onSubmit={onSubmit} style={{ width: '320px' }}>
        <Input
          name="password"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          style={styles.input}
        />

        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isButtonHovered ? styles.buttonHover : null)
          }}
          onMouseEnter={() => buttonHover(true)}
          onMouseLeave={() => buttonHover(false)}
        >
          Enter
        </button>
      </form>

     
      
    </div>

    </>
  );
};

export default PasswordProtect;
